import jsep from 'jsep';
jsep.addBinaryOp('=', 10);
export function isQueryValid(query) {
    try {
        visitNode(jsep(query));
        return true;
    }
    catch (e) {
        return false;
    }
}
export function parseQueryToSearchFilters(query, currentDate = null) {
    return visitNode(jsep(query));
}
function visitNode(node) {
    let searchFilter = '';
    switch (node.type) {
        case 'Compound':
            for (let i in node.body) {
                searchFilter += visitNode(node.body[i]);
            }
            break;
        case 'BinaryExpression':
            searchFilter += visitNode(node.left) + ' ';
            searchFilter += normalizeOperator(node.operator) + ' ';
            searchFilter += visitNode(node.right) + ' ';
            break;
        case 'SequenceExpression':
            searchFilter += '(';
            for (let i in node.expressions) {
                searchFilter += visitNode(node.expressions[i]);
            }
            searchFilter += ') ';
            break;
        case 'Identifier':
            searchFilter += normalizeIdentifier(node.name.toLowerCase()) + ' ';
            break;
        case 'Literal':
            let value = node.value;
            if (typeof value === 'string') {
                if (value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
                    // Date
                    searchFilter += parseInt(value.replace(/-/g, '')) + ' ';
                }
                else {
                    // Other fields
                    searchFilter += "'" + value.replace(/'/, "'") + "' ";
                }
            }
            else if (typeof value === 'boolean') {
                searchFilter += value ? 'true ' : 'false ';
            }
            else if (typeof value === 'number') {
                searchFilter += value + ' ';
            }
            break;
    }
    return searchFilter;
}
function normalizeOperator(operator) {
    const operators = {
        '==': '=',
        '=': '=',
        '!=': '!=',
        '>': '>',
        '>=': '>=',
        '<': '<',
        '<=': '<=',
    };
    if (typeof operators[operator] === 'undefined') {
        throw new Error('Invalid operator ' + operator);
    }
    return operators[operator];
}
function normalizeIdentifier(identifier) {
    const identifiers = {
        // Operators
        et: 'AND',
        and: 'AND',
        ou: 'OR',
        or: 'OR',
        // Fields
        prenom: 'profile_first_name',
        nom: 'profile_last_name',
        datenaissance: 'profile_birthdate_int',
        age: 'profile_age',
        pays: 'area_country_code',
        region: 'area_province_name',
        departement: 'area_district_name',
        codepostal: 'area_zip_code_name',
        tags: 'tags_names',
        projects: 'projects_names',
        newsletters: 'settings_receive_newsletters',
        sms: 'settings_receive_sms',
        appels: 'settings_receive_calls',
        date: 'created_at_int',
        statut: 'status',
    };
    if (typeof identifiers[identifier] === 'undefined') {
        throw new Error('Invalid identifier ' + identifier);
    }
    return identifiers[identifier];
}
