import { createMeiliSearchIndex } from '../../../bridge/meilisearch';
import { parseQueryToSearchFilters } from './queryParser';
var CrmFacet;
(function (CrmFacet) {
    CrmFacet["projects"] = "projects";
    CrmFacet["area_country_code"] = "area_country_code";
    CrmFacet["area_province_name"] = "area_province_name";
    CrmFacet["area_district_name"] = "area_district_name";
    CrmFacet["tags_names"] = "tags_names";
    CrmFacet["status"] = "status";
    CrmFacet["settings_receive_newsletters"] = "settings_receive_newsletters";
    CrmFacet["settings_receive_sms"] = "settings_receive_sms";
    CrmFacet["settings_receive_calls"] = "settings_receive_calls";
})(CrmFacet || (CrmFacet = {}));
export const FACETS_LIST = Object.keys(CrmFacet);
const ITEMS_PER_PAGE = 25;
export class SearchEngine {
    constructor(endpoint, token, index, project = null) {
        // Search options
        this.project = null;
        this.query = '';
        this._isQueryLanguage = false;
        this.facetsFilter = {};
        this.offset = 0;
        this.sortField = 'created_at';
        this.sortType = 'desc';
        // Previous response (for loading more and facets)
        this.results = [];
        this.facets = null;
        this.meilisearch = createMeiliSearchIndex(endpoint, token, index);
        this.project = project;
        if (this.project) {
            this.includeFilter('projects', this.project);
        }
    }
    refreshResults() {
        return new Promise((resolve, reject) => {
            this.doSearch()
                .then((response) => {
                this.results = response.hits;
                this.facets = response.facetDistribution;
                resolve({
                    hits: this.results,
                    facets: this.facets,
                    nbHits: response.estimatedTotalHits,
                    hasMoreResults: true,
                });
            })
                .catch(reject);
        });
    }
    loadMore() {
        this.offset += ITEMS_PER_PAGE;
        return new Promise((resolve, reject) => {
            this.doSearch()
                .then((response) => {
                this.results = this.results.concat(response.hits);
                this.facets = response.facetDistribution;
                resolve({
                    hits: this.results,
                    facets: this.facets,
                    nbHits: response.estimatedTotalHits,
                    hasMoreResults: response.hits.length > 0,
                });
            })
                .catch(reject);
        });
    }
    getFacetsView() {
        const FILTERS_ORDER = { include: 2, exclude: 1, inactive: 0 };
        let view = {};
        // Sort facets values by filter type and count
        let facetCounts = this.facets;
        for (let facetName in this.facets) {
            // Add excluded values (they won't come up in response as they are excluded)
            for (let value in this.facetsFilter[facetName]) {
                if (this.facetsFilter[facetName][value] === 'exclude') {
                    facetCounts[facetName][value] = 0;
                }
            }
            const currentFacetFilters = this.facetsFilter[facetName] || {};
            let sortable = [];
            for (let value in facetCounts[facetName]) {
                sortable.push([value, currentFacetFilters[value] || 'inactive', facetCounts[facetName][value]]);
            }
            sortable.sort((a, b) => {
                if (0 === FILTERS_ORDER[b[1]] - FILTERS_ORDER[a[1]]) {
                    return b[2] - a[2];
                }
                return FILTERS_ORDER[b[1]] - FILTERS_ORDER[a[1]];
            });
            view[facetName] = sortable.map((item) => {
                return {
                    value: item[0],
                    status: item[1],
                    count: item[2],
                };
            });
        }
        return view;
    }
    getActiveFacetsFilters() {
        let active = {};
        for (let facetName in this.facetsFilter) {
            for (let value in this.facetsFilter[facetName]) {
                if (this.facetsFilter[facetName][value] !== null) {
                    if (typeof active[facetName] === 'undefined') {
                        active[facetName] = {};
                    }
                    active[facetName][value] = this.facetsFilter[facetName][value];
                }
            }
        }
        return active;
    }
    setQuery(query) {
        this.offset = 0;
        this.query = query;
    }
    setMode(isQueryLanguage) {
        this._isQueryLanguage = isQueryLanguage;
        this.offset = 0;
        this.query = '';
    }
    setSort(fieldName, type) {
        this.sortField = fieldName;
        this.sortType = type;
    }
    includeFilter(facetName, value) {
        this.setFilter(facetName, value, 'include');
    }
    excludeFilter(facetName, value) {
        this.setFilter(facetName, value, 'exclude');
    }
    cancelFilter(facetName, value) {
        this.setFilter(facetName, value, null);
    }
    resetFilters() {
        this.offset = 0;
        this.query = '';
        this.facetsFilter = {};
        if (this.project) {
            this.includeFilter('projects', this.project);
        }
    }
    getQuery() {
        return this.query;
    }
    isQueryLanguage() {
        return this._isQueryLanguage;
    }
    getSortField() {
        return this.sortField;
    }
    getSortType() {
        return this.sortType;
    }
    getBatchPayload() {
        let filter = this.createSearchFilter();
        if (this._isQueryLanguage) {
            filter = [parseQueryToSearchFilters(this.query), ...filter];
        }
        return {
            queryInput: this._isQueryLanguage ? '' : this.query,
            queryFilter: filter,
            querySort: this.createSearchSort(),
        };
    }
    doSearch() {
        let filter = this.createSearchFilter();
        if (this._isQueryLanguage) {
            filter = [parseQueryToSearchFilters(this.query), ...filter];
        }
        return this.meilisearch.search(this._isQueryLanguage ? '' : this.query, {
            filter: filter,
            sort: this.createSearchSort(),
            offset: this.offset,
            limit: ITEMS_PER_PAGE,
            facets: FACETS_LIST,
            attributesToRetrieve: [
                'id',
                'email',
                'contact_additional_emails',
                'contact_phone',
                'profile_first_name',
                'profile_last_name',
                'address_street_line1',
                'address_street_line2',
                'address_zip_code',
                'address_city',
                'address_country',
                'social_facebook',
                'social_twitter',
                'social_linked_in',
                'social_telegram',
                'social_whatsapp',
                'profile_birthdate',
                'picture',
                'email_hash',
                'status',
                'settings_receive_newsletters',
                'settings_receive_calls',
                'settings_receive_sms',
                'created_at',
                'area_country',
                'area_country_code',
                'area_province',
                'area_province_name',
                'area_district',
                'area_district_name',
                'area_community',
                'area_community_name',
                'area_zip_code',
                'area_zip_code_name',
                'tags',
                'tags_names',
                'projects',
                'projects_names',
            ],
        });
    }
    createSearchFilter() {
        let filter = [];
        for (let facetName in this.facetsFilter) {
            for (let value in this.facetsFilter[facetName]) {
                if (this.facetsFilter[facetName][value]) {
                    filter.push(facetName +
                        ' ' +
                        (this.facetsFilter[facetName][value] === 'include' ? '=' : '!=') +
                        ' ' +
                        "'" +
                        value +
                        "'");
                }
            }
        }
        return filter;
    }
    createSearchSort() {
        return [this.sortField + ':' + this.sortType];
    }
    setFilter(facetName, value, type) {
        this.offset = 0;
        if (typeof this.facetsFilter[facetName] === 'undefined') {
            this.facetsFilter[facetName] = {};
        }
        this.facetsFilter[facetName][value] = type;
    }
}
